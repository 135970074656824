<script setup lang="ts">
import { PawQuantitySelector } from '@lyka-pet-food/lyka-common'
import { defineProps, ref, defineEmits } from 'vue'

const props = withDefaults(
  defineProps<{
    subTitle?: string
    title?: string
    quantity: number
    imagePath?: string
    mealIconPath?: string
    recommended?: boolean
    unrecommendedReason?: string
  }>(),
  {
    recommended: true,
  },
)

const mealsQuantity = ref(props.quantity)

const emits = defineEmits<{
  (e: 'updateQuantity', quantity: number): void
  (e: 'viewMealsDetail'): void
}>()

const onClickAddMeals = (): void => {
  mealsQuantity.value++
  emits('updateQuantity', mealsQuantity.value)
}

const onClickRemoveMeals = (): void => {
  if (mealsQuantity.value > 0) {
    mealsQuantity.value--
    emits('updateQuantity', mealsQuantity.value)
  }
}

const onClickViewMealsDetail = (): void => {
  emits('viewMealsDetail')
}

const onUpdateQuantity = (quantity: number | undefined): void => {
  if (!quantity) return

  mealsQuantity.value = Number(quantity)
  emits('updateQuantity', mealsQuantity.value)
}
</script>

<template>
  <div class="tw-w-full tw-flex md:tw-flex-col md:tw-space-y-4 tw-space-x-5 md:tw-space-x-0">
    <div
      class="tw-w-1/3 max-[360px]:tw-h-[85px] md:tw-w-[229px] tw-h-[115px] md:tw-h-[164px] tw-bg-surface-brand-secondary tw-overflow-hidden tw-rounded-xl tw-flex tw-items-center tw-justify-center"
      @click="onClickViewMealsDetail"
    >
      <div
        v-if="!props.recommended"
        class="tw-backdrop-grayscale tw-bg-white/5 tw-w-1/3 tw-h-[115px] md:tw-w-[229px] md:tw-h-[164px] max-[360px]:tw-h-[85px] tw-absolute tw-z-10"
      ></div>

      <div class="tw-w-4/5 tw-h-auto tw-relative">
        <img :src="props.imagePath" class="tw-w-full tw-h-auto tw-scale-[1.3] md:tw-scale-100" />
        <img
          :src="props.mealIconPath"
          class="tw-absolute tw-right-2 md:tw-right-5 -tw-bottom-1.5 md:tw-bottom-9 max-[360px]:tw-w-1/3 tw-w-[31px]"
        />
      </div>
    </div>

    <div class="tw-flex tw-flex-col tw-w-2/3 md:tw-w-full tw-space-y-4">
      <div class="tw-space-y-0.5">
        <div class="tw-text-body-md tw-flex tw-pb-1">
          {{ props.subTitle }}
        </div>
        <div class="tw-text-body-lg">{{ props.title }}</div>
        <div class="tw-text-content-accent tw-underline tw-cursor-pointer" @click="onClickViewMealsDetail">
          Show details
        </div>
      </div>

      <div class="tw-w-[165px] tw-pt-2 md:tw-pt-0">
        <PawQuantitySelector
          data-testid="quantity-selector"
          v-model="mealsQuantity"
          @click-plus="onClickAddMeals"
          @click-minus="onClickRemoveMeals"
          @update:model-value="onUpdateQuantity"
          :disable-minus="mealsQuantity === 0"
          :disable-plus="mealsQuantity >= 100"
        />
      </div>
    </div>
  </div>
</template>
