import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { DogsDataResponse, useDogs } from '@/composables/useDogs'
import { RetrieveDogReturnDto } from '@repo/api-types/src/entities'

export const useAnimalsStore = defineStore('animals', () => {
  const dogs = ref<RetrieveDogReturnDto[]>([])
  const dogsData = ref<DogsDataResponse>()
  const loading = ref(false)

  const loadDogs = async () => {
    const { returnedDogs, error } = await useDogs().getDogs()

    // TODO: Move to error  handling
    if (error) {
      console.error(error)
      return
    }

    if (returnedDogs) {
      dogs.value = returnedDogs
    }
  }

  const loadDogsData = async () => {
    const { data, error } = await useDogs().getDogsData()

    // TODO: Move to error  handling
    if (error) {
      console.error(error)
      return
    }

    if (data) {
      dogsData.value = data
    }
  }

  const load = async () => {
    loading.value = true
    await Promise.all([loadDogs(), loadDogsData()])
    loading.value = false
  }

  const getDogById = (petId: string) => {
    return dogs.value?.find((animal) => animal.identifier === petId)
  }

  return {
    loading,
    dogs,
    dogsData,
    getDogById,
    load,
  }
})
