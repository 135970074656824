import type {
  GetCurrentOrderResponse,
  SubscriptionContractLineItemSchema,
  ReadProductResponse,
  ReadProductVariantResponse,
  SubscriptionContractBillingCycleSchema,
} from './ecommerce'

export * from './courier'
export * from './courier/validations/deliverySchedule'

export enum OrderStatusEnum {
  UPNEXT = 'UPNEXT',
  PAST = 'PAST',
  PREPARING = 'PREPARING',
  DELIVERED = 'DELIVERED',
  FUTURE = 'FUTURE',
}

export type CurrentOrderResult = NonNullable<GetCurrentOrderResponse['result']>
export type FutureBillingCyclesResult = Array<SubscriptionContractLineItemSchema>
export type EcommerceProduct = ReadProductResponse['result']
export type ProductVariant = ReadProductVariantResponse['result']

export type TimeSlot = 'AM' | 'PM'

export type CustomAttributes = Array<{ key: string; value: string }>

export type MetaFields = Array<{ metaKey: string; metaValue: string }>

export type SubscriptionBillingCycleBillingCycleStatus = 'BILLED' | 'UNBILLED'

export type OrderStatus = keyof typeof OrderStatusEnum

export type Customer = {
  id?: string
  email?: string
  firstName?: string
  lastName?: string
  createdAt?: string
  cognitoUserId?: string
  numberOfOrders?: number
  subscriptionContracts?: Array<{
    id?: string
    nextBillingDate?: string
    status?: string
  }>
}

export type ShippingAddress = {
  id?: string
  address1?: string
  address2?: string
  city?: string
  provinceCode?: string
  countryCodeV2?: string
  postCode?: string
}

export type BillingAddress = {
  address1?: string
  address2?: string
  city?: string
  countryCodeV2?: string
  country?: string
}

export type BoxItem = {
  lineItemId?: string
  productVariantId?: string
  productVariantSku?: string
  quantity?: number
}

export type BoxDetail = {
  boxId?: string
  fulfillmentOrderId?: string
  remainingBoxVolume?: number
  remainingBoxWeight?: number
  boxItems?: Array<BoxItem>
}

export type LineItem = {
  lineItemId: string
  productId: string
  productVariantId: string
  productVariantSku?: string
  quantity: number
  title: string
  price: number
  productVariantWidth?: number
  productVariantDepth?: number
  productVariantHeight?: number
  oneOff: boolean
  customAttributes?: CustomAttributes
}

export type OrderDto = {
  id?: string
  /**
   * This perId from animal service is stored in the shopify metafields
   */
  petId?: string
  courierShipmentId?: string
  courierInvoiceId?: string
  courierTrackingId?: string
  courierStatus?: string
  subscriptionContractId?: string | null
  customAttributes?: Array<{
    key?: string
    value?: string
  }>
  fulfillmentOrders?: Array<{
    fulfillmentOrderId?: string
    status?: 'CANCELLED' | 'CLOSED' | 'INCOMPLETE' | 'IN_PROGRESS' | 'ON_HOLD' | 'OPEN' | 'SCHEDULED'
  }>
  lines?: Array<LineItem>
  boxDetails?: Array<BoxDetail>
  fulfillmentStationId?: string
  erpSystemOrderId?: string
  cancelReason?: 'CUSTOMER' | 'DECLINED' | 'FRAUD' | 'INVENTORY' | 'OTHER' | 'STAFF'
  lykaOrderStatus?: string
  cancelledAt?: string | null
  closed?: boolean
  closedAt?: string | null
  confirmationNumber?: string
  confirmed?: boolean
  createdAt?: string
  currencyCode?: string
  currentSubtotalLineItemsQuantity?: number
  currentSubtotalPriceSet?: {
    [key: string]: unknown
  }
  subtotalLineItemsQuantity?: number
  discountCode?: string
  displayFulfillmentStatus?:
    | 'FULFILLED'
    | 'IN_PROGRESS'
    | 'ON_HOLD'
    | 'OPEN'
    | 'PARTIALLY_FULFILLED'
    | 'PENDING_FULFILLMENT'
    | 'SCHEDULED'
    | 'UNFULFILLED'
  edited?: boolean
  email?: string
  phone?: string
  merchantEditable?: boolean
  name?: string
  note?: string
  totalWeight?: string
  updatedAt?: string
  customer?: {
    id?: string
    firstName?: string
    lastName?: string
    email?: string
  }
  billingAddress?: {
    address1?: string
    address2?: string | null
    city?: string
    countryCodeV2?: string
    country?: string
  }
  shippingAddress?: {
    id?: string
    address1?: string
    address2?: string | null
    city?: string
    countryCode?: string
    state?: string
    provinceCode?: string
    postCode?: string
    firstName?: string
    lastName?: string
  }
  totalPrice?: number
  discountedPrice?: number
  transaction?: {
    gateway?: string
    id?: string
    paymentId?: string
    status?: 'AWAITING_RESPONSE' | 'ERROR' | 'FAILURE' | 'PENDING' | 'SUCCESS'
    createdAt?: string
    paymentDetails?: {
      CardPaymentDetails?: {
        number?: string
        expirationMonth?: number
        expirationYear?: number
      }
    }
  }
}

export type OrderCustomAttributes = {
  subscriptionId: string
  customerId: string
  cognitoUserId: string
  petId: string
  // Delivery details
  zoneId: string
  courierId: string
  deliveryTimePreference: string
  makeCutoffDateDeltaInSeconds?: number
  manifestCutoffDateDeltaInSeconds?: number
  dispatchCutoffDateDeltaInSeconds?: number
  fulfillDate: string
  makeCutoffDate: string
  makeDateDelta: number
  manifestCutoffDateDelta: number
  dispatchCutoffDateDelta: number
}

export type Order = {
  id: string
  courierShipmentId?: string
  courierInvoiceId?: string
  courierTrackingId?: string
  courierStatus?: string
  orderStatus: OrderStatus
  boxDetails?: Array<BoxDetail>
  customer: Customer
  billingAddress?: BillingAddress
  shippingAddress: ShippingAddress
  totalPrice: number
  discountedPrice: number
  lines: Array<LineItem>
} & OrderCustomAttributes

export type BillingCycleOrderDto = SubscriptionContractBillingCycleSchema

export type BillingCycleCustomAttributes = {
  subscriptionId: string
  customerId: string
  cognitoUserId: string
  petId: string
  // Delivery details
  zoneId: string
  courierId: string
  deliveryTimePreference: string
  makeCutoffDateDeltaInSeconds?: number
  manifestCutoffDateDeltaInSeconds?: number
  dispatchCutoffDateDeltaInSeconds?: number
  deliveryDateDeltaInSeconds?: number
}

export type BillingCycleOrder = {
  subscriptionContractId: string
  cycleIndex: number
  cycleStartAt: string
  cycleEndsAt: string
  paymentStatus: SubscriptionBillingCycleBillingCycleStatus
  orderStatus: OrderStatus
  shippingAddress: ShippingAddress
  lines: Array<LineItem>
  expectedDeliveryDate: string
  totalPrice: number
  discountedPrice: number
} & BillingCycleCustomAttributes

export type SubscriptionContractLineItem = {
  subscriptionLineId: string
  sellingPlanId: string
  productKey: string
  productVariantId: string
  price: string
  quantity: number
}

export type SubscriptionContractCustomAttributes = {
  subscriptionId: string
  customerId: string
  cognitoUserId: string
  petId: string
  zoneId: string
  deliveryTimePreference: TimeSlot
}

export type SubscriptionContract = {
  id: string
  billingPolicyDay: number
  createdAt: string
  deliveryPolicyDay: number
  deliveryPolicyType: string
  subscriptionContractProducts: Array<SubscriptionContractLineItem>
  subscriptionStatus: string
  customAttributes: any
  billingDeliveryRecurringIntervalValue: number
} & SubscriptionContractCustomAttributes

export type SubscriptionMeta = {
  mealPlanTemplateId: string
  mealPlanTemplateName: string
  totalPouches: string
  pouchSizeInGrams: string
  dailyServingSizeInGrams: string
}

export type Subscription = {
  subscriptionId: string
  subscriptionName: string
  subscriptionDescription: string
  subscriptionSellingPlanId: string
  subscriptionForCustomerId: string
  subscriptionForPetId: string
  subscriptionForProducts: Array<{ productId: string; productKey: string }>
} & SubscriptionMeta

export type Product = {
  productId: string
  eyebrowHeading: string
  displayName: string
  productType: string
  imageUrl: string
  mealIconUrl: string
}
