<script setup lang="ts">
import { computed, ref } from 'vue'
import { IonPage } from '@ionic/vue'
import ViewLayout from '@/components/ViewLayout.vue'
import CalendarTab from '@/components/calendar/CalendarTab.vue'
import { PawHeading } from '@lyka-pet-food/lyka-common'
import { type Order, type BillingCycleOrder, type LineItem, OrderStatusEnum } from '@repo/api-types'
import DeliverySlideRecent from '@/components/DeliverySlideRecent.vue'
import DeliverySlideFuture from '@/components/DeliverySlideFuture.vue'
import { useOrdersStore } from '@/stores/orders'

const props = defineProps<{
  loading: boolean
}>()

const emits = defineEmits<{
  (e: 'editRecentOrderMeals', lineItems: LineItem[], order: Order): void
  (e: 'editFutureOrderMeals', lineItems: LineItem[], order: BillingCycleOrder): void
  (e: 'addFutureOrderProducts', lineItems: LineItem[], order: BillingCycleOrder): void
  (e: 'editFutureOrderProducts', lineItems: LineItem[], order: BillingCycleOrder): void
  (e: 'editFutureOrderDeliveryDate', date: string, slot: string, order: BillingCycleOrder): void
}>()

const activeIndex = ref(1)
const slideFadeAnimationName = ref('')

const ordersStore = useOrdersStore()

const allOrders = computed(() => {
  return [...ordersStore.recentOrders, ...ordersStore.futureOrders]
})

const deliveryDetails = computed(() => {
  const details = []
  for (let order of ordersStore.recentOrders) {
    details.push({
      status: order.orderStatus,
      date: order.makeCutoffDate,
      slot: order.deliveryTimePreference,
    })
  }

  for (let order of ordersStore.futureOrders) {
    details.push({
      status: order.orderStatus,
      date: order.expectedDeliveryDate,
      slot: order.deliveryTimePreference,
    })
  }
  return details
})

const futureDeliveryDetails = computed(() => {
  const details = []

  for (let order of ordersStore.futureOrders) {
    details.push({
      status: order.orderStatus,
      date: order.expectedDeliveryDate,
      slot: order.deliveryTimePreference,
    })
  }
  return details
})

const scrollToItem = (index: number) => {
  if (index > activeIndex.value) {
    slideFadeAnimationName.value = 'slide-fade-right'
  } else {
    slideFadeAnimationName.value = 'slide-fade-left'
  }
  activeIndex.value = index
}

const calendarBgSvgTransition = computed(() => {
  const orderStatus = allOrders.value[activeIndex.value]?.orderStatus
  if (orderStatus === OrderStatusEnum.PAST) {
    return 'tw-translate-x-[366px]'
  } else if (orderStatus === OrderStatusEnum.PREPARING) {
    return 'tw-translate-x-[200px]'
  } else if (orderStatus === OrderStatusEnum.UPNEXT) {
    return 'tw-translate-x-[120px]'
  } else if (orderStatus === OrderStatusEnum.FUTURE) {
    return 'tw-translate-x-[40px]'
  } else {
    return ''
  }
})

const onEditRecentOrderMeals = (lineItems: LineItem[], order: Order) => {
  emits('editRecentOrderMeals', lineItems, order)
}

const onEditFutureOrderMeals = (lineItems: LineItem[], order: BillingCycleOrder) => {
  emits('editFutureOrderMeals', lineItems, order)
}

const onAddFutureOrderProducts = (lineItems: LineItem[], order: BillingCycleOrder) => {
  emits('addFutureOrderProducts', lineItems, order)
}

const onEditFutureOrderProducts = (lineItems: LineItem[], order: BillingCycleOrder) => {
  emits('editFutureOrderProducts', lineItems, order)
}

const onEditFutureOrderDeliveryDate = (deliveryDetail: { date: string; slot: string }, order: BillingCycleOrder) => {
  emits('editFutureOrderDeliveryDate', deliveryDetail.date, deliveryDetail.slot, order)
}
</script>

<template>
  <ViewLayout>
    <template #preView>
      <div class="md:tw-flex md:tw-justify-center">
        <img
          src="@/assets/images/deliveries/order-calendar-bg-mobile.svg"
          class="tw-absolute tw-z-10 tw-max-h-[100px] tw-top-[75px] md:tw-hidden tw-scale-[3] tw-transition-all tw-transform tw-duration-300"
          :class="calendarBgSvgTransition"
        />
        <img
          src="@/assets/images/deliveries/order-bg-desktop.svg"
          class="tw-absolute tw-h-[540px] tw-object-cover -tw-top-[115px] tw-z-10 tw-hidden md:tw-inline"
        />
        <div class="tw-w-full tw-text-center tw-space-y-4 tw-pt-12 tw-relative tw-mb-8 tw-z-20">
          <PawHeading size="5xl" class=""><span class="tw-text-content-invert">Deliveries</span></PawHeading>

          <div class="tw-w-full">
            <CalendarTab
              :delivery-details="deliveryDetails"
              :default-index="activeIndex"
              @scroll-to-item="scrollToItem"
            />
          </div>
        </div>
      </div>
    </template>

    <TransitionGroup :name="slideFadeAnimationName">
      <template v-for="(order, orderIndex) in ordersStore.recentOrders">
        <DeliverySlideRecent
          v-if="activeIndex === orderIndex"
          :data-testid="`test-recent-delivery-slide-${orderIndex}`"
          :key="`recent-order-${orderIndex}`"
          :delivery-detail="deliveryDetails[orderIndex]"
          :order="ordersStore.recentOrders[orderIndex]"
          :class="orderIndex === 0 ? 'tw-mt-8' : ''"
          @edit-meals="onEditRecentOrderMeals($event, order)"
        />
      </template>
      <template v-for="(order, orderIndex) in ordersStore.futureOrders">
        <DeliverySlideFuture
          v-if="activeIndex === orderIndex + ordersStore.recentOrders.length"
          :data-testid="`test-future-delivery-slide-${orderIndex}`"
          :key="`future-order-${orderIndex}`"
          :delivery-detail="futureDeliveryDetails[orderIndex]"
          :order="ordersStore.futureOrders[orderIndex]"
          :class="orderIndex === 0 ? 'tw-mt-8' : ''"
          @edit-meals="onEditFutureOrderMeals($event, order)"
          @add-products="onAddFutureOrderProducts($event, order)"
          @edit-products="onEditFutureOrderProducts($event, order)"
          @edit-delivery-date="onEditFutureOrderDeliveryDate($event, order)"
        />
      </template>
    </TransitionGroup>
  </ViewLayout>
</template>

<style scoped>
.slide-fade-right-enter-from,
.slide-fade-left-leave-to {
  @apply tw-transform tw-translate-x-full md:tw-translate-x-0 md:tw-opacity-0;
}

.slide-fade-right-enter-active,
.slide-fade-right-leave-active,
.slide-fade-left-enter-active,
.slide-fade-left-leave-active {
  @apply tw-duration-300 tw-ease-in-out md:tw-duration-100 -tw-z-20;
}

.slide-fade-right-enter-to,
.slide-fade-right-leave-from,
.slide-fade-left-enter-to,
.slide-fade-left-leave-from {
  @apply tw-transform tw-translate-x-0 md:tw-opacity-100 md:tw-delay-300;
}

.slide-fade-right-leave-to,
.slide-fade-left-enter-from {
  @apply tw-transform -tw-translate-x-full md:tw-translate-x-0 md:tw-opacity-0;
}
</style>
