import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useAppStateStore = defineStore('appState', () => {
  const loadingState = ref(false)
  const successState = ref(false)
  const footerState = ref(true)

  const updateSuccessState = (updateState: boolean) => (successState.value = updateState)

  const updateLoadingState = (updateState: boolean) => (loadingState.value = updateState)

  const updateFooterState = (updateState: boolean) => (footerState.value = updateState)

  return {
    loadingState,
    successState,
    footerState,
    updateSuccessState,
    updateLoadingState,
    updateFooterState,
  }
})
