import { useClient } from '@/composables/useClient'
import { RetrieveDogReturnDto } from '@repo/api-types/src/entities'
import { ClientInferResponseBody } from '@ts-rest/core'
import { contract } from '@repo/api-contracts'

export type DogsDataResponse = ClientInferResponseBody<typeof contract.entities.getDogsData>

export const useDogs = () => {
  const getDogs = async (): Promise<{
    error?: string | null
    returnedDogs?: RetrieveDogReturnDto[]
  }> => {
    const client = await useClient()
    const { status, body } = await client.entities.getDogs()

    let error = null
    if (status == 500) {
      error = body.error
      return { error }
    } else if (status == 200) {
      return { returnedDogs: body }
    }

    return { error: 'Unspecified server error' }
  }

  const getDogsData = async (): Promise<{
    error?: string | null
    data?: DogsDataResponse
  }> => {
    const client = await useClient()
    const { status, body } = await client.entities.getDogsData()

    let error = null
    if (status == 500) {
      error = body.error
      return { error }
    } else if (status == 200) {
      return { data: body }
    }

    return { error: 'Unspecified server error' }
  }

  return {
    getDogs,
    getDogsData,
  }
}
