import { initContract } from '@ts-rest/core'
import { authContract } from './auth'
import { entitiesContract } from './entities'
import { fulfillmentContract } from './fulfillment'
import { ecommerceContract } from './ecommerce'
import { courierContract } from './courier'

const c = initContract()

export const contract = c.router({
  auth: authContract,
  entities: entitiesContract,
  fulfillment: fulfillmentContract,
  ecommerce: ecommerceContract,
  courier: courierContract,
})

export { authContract, entitiesContract, fulfillmentContract, ecommerceContract, courierContract }
