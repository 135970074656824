import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import { IonicVue } from '@ionic/vue'
import { LDPlugin } from 'launchdarkly-vue-client-sdk'
import { createPinia } from 'pinia'
import { segmentPlugin } from './plugins/segment'
import * as SentryVue from '@sentry/vue'
import * as Sentry from '@sentry/capacitor'

/* Import I18n package for internationalization */
import { createI18n } from 'vue-i18n'

import './design'

const i18n = createI18n({})
const pinia = createPinia()
const app = createApp(App)
  .use(i18n)
  .use(IonicVue)
  .use(router)
  .use(LDPlugin, { clientSideID: import.meta.env.VITE_LD_CLIENT_SIDE_ID, deferInitialization: true })
  .use(segmentPlugin, { writeKey: import.meta.env.VITE_SEGMENT_WRITE_KEY })
  .use(pinia)

Sentry.init(
  {
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [SentryVue.browserTracingIntegration(), SentryVue.replayIntegration()],
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost'],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  },
  // Forward the init method from @sentry/vue
  SentryVue.init,
)

router.isReady().then(() => {
  app.mount('#app')
})
