<script setup lang="ts">
import { IonSegment, IonSegmentButton, IonLabel, IonIcon } from '@ionic/vue'
import TruckIcon from '@/assets/icons/truck.svg'
import PawIcon from '@/assets/icons/paw.svg'
import ShopIcon from '@/assets/icons/shop.svg'
import UserIcon from '@/assets/icons/user.svg'
import { useRoute, useRouter } from 'vue-router'

const route = useRoute()
const router = useRouter()
const goToView = (e: CustomEvent) => {
  router.push({ name: e.detail.value })
}
</script>

<template>
  <ion-segment mode="md" :value="route.name as string" @ion-change="goToView">
    <ion-segment-button value="deliveries">
      <ion-label class="tw-normal-case tw-text-xs tw-mt-0.5">Deliveries</ion-label>
      <ion-icon :icon="TruckIcon"></ion-icon>
    </ion-segment-button>
    <ion-segment-button value="pet">
      <ion-label class="tw-normal-case tw-text-xs tw-mt-0.5">Dog</ion-label>
      <ion-icon :icon="PawIcon"></ion-icon>
    </ion-segment-button>
    <ion-segment-button value="shop">
      <ion-label class="tw-normal-case tw-text-xs tw-mt-0.5">Shop</ion-label>
      <ion-icon :icon="ShopIcon"></ion-icon>
    </ion-segment-button>
    <ion-segment-button value="account" router-link="">
      <ion-label class="tw-normal-case tw-text-xs tw-mt-0.5">Account</ion-label>
      <ion-icon :icon="UserIcon"></ion-icon>
    </ion-segment-button>
  </ion-segment>
</template>

<style>
ion-segment {
  --background: var(--body-secondary);
}

ion-segment-button.md {
  --color-checked: var(--primary-fg);
  --indicator-height: 4px;
  --indicator-transform: translateY(-74px);
  --padding-bottom: 4px;
  --padding-top: 2px;
}

.segment-button-checked ion-icon {
  fill: var(--primary-fg);
}
</style>
