import { useClient } from '@/composables/useClient'
import type { Subscription, SubscriptionContract } from '@repo/api-types'

export const useSubscriptions = async (): Promise<{
  error?: string | null
  subscriptions?: Subscription[]
  subContracts?: SubscriptionContract[]
}> => {
  const client = await useClient()
  const { status, body } = await client.ecommerce.getSubscriptions()

  let error = null
  if (status == 500) {
    error = body.error
    return { error }
  } else if (status == 200) {
    return { subscriptions: body.subscriptions, subContracts: body.subContracts }
  }

  return { error: 'Unspecified server error' }
}
