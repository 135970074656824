<script setup lang="ts">
import { useRouter } from 'vue-router'
import { useForgotPassword } from '@/composables/useForgotPassword'
import ForgotPassword from './ForgotPassword.vue'
import { ref } from 'vue'

const router = useRouter()

const loading = ref(false)

const doForgotPassword = async (email: string) => {
  try {
    loading.value = true
    const { error, success } = await useForgotPassword({ email })

    if (error) {
      // TODO: Render user error
      throw error
    }

    if (success) {
      router.push({ name: 'email-sent' })
    }
  } finally {
    loading.value = false
  }
}
</script>

<template>
  <ForgotPassword @submit="doForgotPassword" :loading="loading" />
</template>
