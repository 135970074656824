<script setup lang="ts">
import { OrderStatus, DeliverySlot } from '@/types/order'
import { computed, ref } from 'vue'
import { PawHeading, PawDivider, PawButtonOutlined } from '@lyka-pet-food/lyka-common'
import RecipeCard from '@/components/RecipeCard.vue'
import type { BillingCycleOrder, LineItem } from '@repo/api-types'
import { useProductsStore } from '@/stores/products'
import { transformShippingAddress } from '@/utils/transforms/transformShippingAddress'
import { ProductType } from '@/types/products'
import { useSubscriptionsStore } from '@/stores/subscriptions'
import { useAnimalsStore } from '@/stores/animals'

const props = defineProps<{
  deliveryDetail: {
    status: string
    date: string
    slot: string
  }
  order: BillingCycleOrder
}>()

const emits = defineEmits<{
  (e: 'editDeliveryDate', delivery: { date: string; slot: string }): void
  (e: 'delayDelivery'): void
  (e: 'editFeedback'): void
  (e: 'editMeals', lineItems: LineItem[]): void
  (e: 'addProducts', lineItems: LineItem[]): void
  (e: 'editProducts', lineItems: LineItem[]): void
  (e: 'editExtras'): void
  (e: 'showPaymentDetails'): void
}>()

const productsStore = useProductsStore()
const subscriptionsStore = useSubscriptionsStore()
const animalsStore = useAnimalsStore()

const extrasLineItems = computed(() => {
  return props.order.lines.filter(
    (lineItem) =>
      productsStore.getProductByProductVariantId(lineItem.productVariantId)?.productType === ProductType.TREAT,
  )
})

const mealLineItems = computed(() => {
  return props.order.lines.filter(
    (lineItem) =>
      productsStore.getProductByProductVariantId(lineItem.productVariantId)?.productType === ProductType.MEAL,
  )
})

const isMealEditable = computed(() => {
  return props.deliveryDetail.status !== OrderStatus.PAST && props.deliveryDetail.status !== OrderStatus.PREPARING
})

const isProductsEditable = computed(() => {
  return isMealEditable && extrasLineItems.value.length > 0
})

const isFeedbackAvailable = computed(() => {
  return props.deliveryDetail.status === OrderStatus.PAST
})

const isDeliveryEditable = computed(() => {
  return props.deliveryDetail.status !== OrderStatus.PAST && props.deliveryDetail.status !== OrderStatus.PREPARING
})

const deliverySlot = computed(() => {
  if (props.deliveryDetail.slot === DeliverySlot.AM) {
    return '0am-7am'
  } else {
    return '8am-6pm'
  }
})

const deliverySlotText = computed(() => {
  if (props.deliveryDetail.status === OrderStatus.PAST) {
    return 'Delivered ' + deliverySlot.value
  } else if (props.deliveryDetail.status === OrderStatus.PREPARING) {
    return 'Arriving ' + deliverySlot.value
  } else if (props.deliveryDetail.status === OrderStatus.UPNEXT) {
    return 'Payment in 14 days'
  } else if (props.deliveryDetail.status === OrderStatus.FUTURE) {
    return 'You can now make changes to this!'
  }
})

const currentSubscription = computed(() => {
  if (subscriptionsStore.userSubscriptions) {
    // FIXME: We need to handle multiple subscriptions for multiple dogs
    return subscriptionsStore.userSubscriptions[0]
  }
})

const onUpdateDeliveryDate = () => {
  emits('editDeliveryDate', { date: props.deliveryDetail.date, slot: props.deliveryDetail.slot })
}
</script>

<template>
  <div
    data-testid="test-delivery-slide"
    class="tw-w-full tw-px-4 tw-space-y-8 tw-absolute tw-pb-24 md:tw-w-[620px] md:tw-pb-64"
  >
    <div class="tw-flex tw-justify-between tw-bg-surface-brand-secondary -tw-mx-4 tw-pt-12 -tw-mt-24 -tw-z-20">
      <img src="@/assets/images/deliveries/banner-dog-1.svg" class="tw-pr-4" />
      <div class="tw-text-right tw-space-y-3 tw-pr-3 tw-relative tw-z-10 tw-flex tw-flex-col tw-mb-4 tw-justify-center">
        <div v-if="!isDeliveryEditable" class="tw-flex tw-justify-end">
          <img src="@/assets/images/deliveries/lock-closed-icon.svg" class="tw-pr-1" />Locked
        </div>
        <div class="tw-text-body-lg">{{ deliverySlotText }}</div>
        <div v-if="isFeedbackAvailable" class="tw-text-content-accent tw-flex tw-cursor-pointer tw-items-center">
          Feedback about delivery<img src="@/assets/images/deliveries/arrow-right-icon.svg" class="tw-pl-2" />
        </div>
      </div>
    </div>

    <div data-testid="test-delivery-info" class="tw-w-full tw-text-body-md tw-text-start tw-flex">
      <img src="@/assets/images/deliveries/pin-icon.svg" class="tw-pr-1 -tw-translate-y-0.5" />
      {{ transformShippingAddress(order.shippingAddress) }}
    </div>

    <div v-if="isDeliveryEditable" class="tw-w-full tw-space-y-4">
      <PawDivider />
      <div class="tw-flex tw-gap-x-4">
        <PawButtonOutlined class="tw-border-solid" @click="onUpdateDeliveryDate">Edit delivery date</PawButtonOutlined>
        <PawButtonOutlined class="tw-border-solid">Delay 1 week</PawButtonOutlined>
      </div>
      <div class="tw-text-body-sm">
        Edit delivery before payment date on <span class="tw-font-bold">Sun, 14 Sept</span>
      </div>
      <PawDivider />
    </div>
    <PawDivider v-else />

    <div class="tw-w-full tw-space-y-4">
      <div class="tw-flex tw-justify-between tw-items-center">
        <PawHeading size="2xl" type="subheading">{{ animalsStore.getDogById(order.petId)?.name }}'s meal</PawHeading>
        <div
          v-if="isMealEditable"
          class="tw-text-body-lg tw-text-content-accent tw-cursor-pointer"
          @click="emits('editMeals', mealLineItems)"
        >
          Edit
        </div>
      </div>
      <div :data-testid="`test-meal-pouches-${deliveryDetail.date}`" class="tw-text-body-sm">
        {{ currentSubscription?.totalPouches }} x {{ currentSubscription?.pouchSizeInGrams }}g pouches • Serving size
        {{ currentSubscription?.dailyServingSizeInGrams }}g/day
      </div>
      <div class="tw-flex tw-flex-wrap tw-gap-y-4">
        <RecipeCard
          v-for="(lineItem, idx) in mealLineItems"
          :key="`bowl-${idx}`"
          :imagePath="productsStore.getImageUrlByProductVariantId(lineItem.productVariantId)"
          :title="lineItem.title"
          :quantity="lineItem.quantity"
        />
      </div>
      <PawDivider />
    </div>

    <div class="tw-w-full tw-space-y-4">
      <div class="tw-flex tw-justify-between tw-items-center">
        <PawHeading size="2xl" type="subheading">Extras</PawHeading>
        <div
          v-if="isProductsEditable"
          class="tw-text-body-lg tw-text-content-accent tw-cursor-pointer"
          @click="emits('editProducts', extrasLineItems)"
        >
          Edit
        </div>
      </div>
      <div class="tw-flex tw-flex-wrap">
        <RecipeCard
          v-for="(lineItem, idx) in extrasLineItems"
          :key="`extra-${idx}`"
          :imagePath="productsStore.getImageUrlByProductVariantId(lineItem.productVariantId)"
          :title="lineItem.title"
          :quantity="lineItem.quantity"
          subscriptionStatus="plan"
        />
        <div
          v-if="isMealEditable"
          class="tw-w-16 tw-h-16 tw-rounded-lg tw-bg-surface-brand-secondary tw-flex tw-justify-center tw-items-center tw-cursor-pointer"
          @click="emits('addProducts', extrasLineItems)"
        >
          <img src="@/assets/images/deliveries/plus-icon.svg" />
        </div>
      </div>
    </div>

    <PawDivider />

    <div
      class="tw-w-full tw-space-y-4 md:tw-space-y-0 tw-flex tw-flex-col md:tw-flex-row tw-justify-center md:tw-justify-between tw-items-center"
    >
      <div data-testid="test-payment" class="tw-text-body-lg">
        Total: <span class="tw-line-through tw-pr-1">${{ order.totalPrice }}</span
        ><span class="tw-font-bold">${{ order.discountedPrice }}</span>
      </div>

      <PawButtonOutlined variant="primary" class="tw-border-solid">See payment details</PawButtonOutlined>
    </div>
  </div>
</template>
