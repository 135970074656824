import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import type { Subscription, SubscriptionContract } from '@repo/api-types'
import { useSubscriptions } from '@/composables/useSubscriptions'

export const useSubscriptionsStore = defineStore('subscriptions', () => {
  const userSubscriptions = ref<Subscription[]>()
  const userSubContracts = ref<SubscriptionContract[]>()
  const loading = ref(false)

  const load = async () => {
    loading.value = true
    const { subscriptions, subContracts, error } = await useSubscriptions()

    // TODO: Move to error  handling
    if (error) {
      console.error(error)
      loading.value = false
      return
    }

    userSubscriptions.value = subscriptions
    userSubContracts.value = subContracts
  }

  return {
    loading,
    userSubContracts,
    userSubscriptions,
    load,
  }
})
