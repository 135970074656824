import { createRouter, createWebHistory } from '@ionic/vue-router'
import { RouteRecordRaw } from 'vue-router'
import { useAuthStore } from '@/stores/auth'
import Login from '@/views/Login.container.vue'
import Deliveries from '@/views/Deliveries.container.vue'
import Pet from '@/views/Pet.vue'
import Shop from '@/views/Shop.vue'
import Account from '@/views/Account.vue'
import ForgotPassword from '@/views/ForgotPassword.container.vue'
import ForgotPasswordEmailSent from '@/views/ForgotPasswordEmailSent.vue'
import { useSegment } from '@/composables/useSegment'

// Route names for access without authentication
const UNAUTHED_ROUTE_NAMES = ['login', 'forgot-password', 'email-sent']

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    redirect: '/deliveries',
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: ForgotPassword,
  },
  {
    path: '/email-sent',
    name: 'email-sent',
    component: ForgotPasswordEmailSent,
  },
  {
    path: '/deliveries',
    name: 'deliveries',
    component: Deliveries,
  },
  {
    path: '/dog',
    name: 'pet',
    component: Pet,
  },
  {
    path: '/shop',
    name: 'shop',
    component: Shop,
  },
  {
    path: '/account',
    name: 'account',
    component: Account,
  },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.VITE_BASE_URL),
  routes,
})

router.beforeEach(async (to, from) => {
  const authStore = useAuthStore()
  await authStore.checkTokenIsExpired()

  if (authStore.isAuthenticated && to.name === 'login') {
    return { name: 'Deliveries' }
  }

  if (!authStore.isAuthenticated && to.name !== 'login' && !UNAUTHED_ROUTE_NAMES.includes(to.name as string)) {
    return { name: 'login' }
  }
})

router.afterEach(async (to, from) => {
  useSegment().track('Viewed Page', { page: to.name as string })
})

export default router
