<script setup lang="ts">
import ViewLayout from '@/components/ViewLayout.vue'
import { withDefaults, ref, onBeforeMount, computed } from 'vue'
import type { LineItem, Product } from '@repo/api-types'
import { PawHeading, PawDivider } from '@lyka-pet-food/lyka-common'
import MealsItem from '@/components/MealsItem.vue'
import StickyBottomNav from '@/components/stickyNav/StickyBottomNav.vue'
import { useProductsStore } from '@/stores/products'

export type EditProductMeal = Product & {
  quantity: number
}

const props = withDefaults(
  defineProps<{
    dogName?: string
    lineItems?: LineItem[]
    productMeals?: Array<Product>
  }>(),
  {},
)

const emits = defineEmits<{
  (e: 'cancelEditMeal'): void
  (e: 'confirmEditMeal', updatedProducts: Array<EditProductMeal>): void
}>()

const editMeals = ref<Array<EditProductMeal>>([])

const productsStore = useProductsStore()

const onUpdateQuantity = (index: number, quantity: number): void => {
  editMeals.value[index].quantity = quantity
}

const totalMealsQuantity = computed(() => {
  return props.lineItems?.reduce((a, b) => a + b.quantity, 0) || 0
})

const selectedMealsQuantity = computed(() => {
  return editMeals.value.reduce((a, b) => a + b.quantity, 0)
})

const updatedProducts = computed(() => {
  return editMeals.value
})

onBeforeMount(() => {
  editMeals.value =
    props.productMeals?.map((product) => {
      return {
        ...product,
        quantity:
          props.lineItems?.find(
            (lineItem) =>
              productsStore.getProductByProductVariantId(lineItem.productVariantId)?.productId === product?.productId,
          )?.quantity || 0,
      }
    }) || []
})
</script>

<template>
  <ViewLayout>
    <div class="tw-max-w-[1140px] tw-space-y-8">
      <div class="tw-flex tw-items-start tw-flex-row tw-self-start">
        <img
          src="@/assets/icons/chevron-left.svg"
          class="tw-pr-4 tw-cursor-pointer md:tw-pt-1"
          @click="emits('cancelEditMeal')"
        />
        <div class="tw-space-y-1">
          <PawHeading size="3xl">Edit {{ props.dogName }}'s meals plan</PawHeading>
          <div class="tw-text-body-md">Changes will be applied to all deliveries</div>
        </div>
      </div>

      <div class="tw-w-full">
        <div
          class="tw-bg-surface-light tw-p-4 min-[1440px]:tw-p-8 tw-rounded-3xl -tw-mx-4 md:tw-mx-0 tw-mb-52 md:tw-w-full md:tw-grid md:tw-grid-cols-3 xl:tw-grid-cols-4 md:tw-gap-8"
        >
          <div v-for="(product, index) in editMeals" :key="`meal-item-${index}`">
            <MealsItem
              :title="product.displayName"
              :sub-title="product.eyebrowHeading"
              :image-path="product.imageUrl"
              :meal-icon-path="product.mealIconUrl"
              :quantity="product.quantity"
              @update-quantity="onUpdateQuantity(index, $event)"
            />
            <PawDivider v-if="index !== editMeals.length - 1" class="tw-my-4 md:tw-hidden" />
          </div>
        </div>
      </div>
    </div>

    <template #postView>
      <StickyBottomNav
        :selected-quantity="selectedMealsQuantity"
        :total-quantity="totalMealsQuantity"
        @cancel="emits('cancelEditMeal')"
        @confirm="emits('confirmEditMeal', updatedProducts)"
      />
    </template>
  </ViewLayout>
</template>
