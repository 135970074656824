import { initContract } from '@ts-rest/core'
import {
  DogBreedReturnDto,
  DogFoodAllergenReturnDto,
  DogFoodTypeReturnDto,
  DogHealthIssueReturnDto,
  RetrieveDogReturnDto,
  UpdateAnimalDto,
} from '@repo/api-types/src/entities'
import z from 'zod'

const c = initContract()

export const entitiesContract = c.router({
  getDogs: {
    method: 'GET',
    path: '/dogs',
    responses: {
      200: c.type<Array<RetrieveDogReturnDto>>(),
      500: c.type<{ error: string }>(),
    },
  },
  getDogsData: {
    method: 'GET',
    path: '/dogs/metadata',
    responses: {
      200: c.type<{
        breeds: DogBreedReturnDto[]
        healthIssues: DogHealthIssueReturnDto[]
        foodTypes: DogFoodTypeReturnDto[]
        foodAllergens: DogFoodAllergenReturnDto[]
      }>(),
      500: c.type<{ error: string }>(),
    },
  },
  updateDog: {
    method: 'PATCH',
    path: '/dogs/:id',
    responses: {
      200: c.type<UpdateAnimalDto>(),
      500: c.type<{ error: string }>(),
    },
    body: z.object({
      identifier: z.string(),
      dog: z.custom<UpdateAnimalDto>(),
    }),
  },
})
